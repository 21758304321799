import React, { useEffect, useRef, useState } from 'react'
import '../../App.css'
import axios from 'axios';
import { useTextToVoice } from "react-speakup";
import iconsend from '../../send.png'
import video from '../../one.mp4'

import { initializeApp } from '@firebase/app';
import { getDatabase } from 'firebase/database';
import { ref, set, get, update, remove, child } from "firebase/database";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyBBDNQ84vjXymTVaoW2b3yHyjIXs4SCfT0",
    authDomain: "smart-wm-bea72.firebaseapp.com",
    databaseURL: "https://smart-wm-bea72-default-rtdb.firebaseio.com",
    projectId: "smart-wm-bea72",
    storageBucket: "smart-wm-bea72.appspot.com",
    messagingSenderId: "221059026615",
    appId: "1:221059026615:web:884c919a5da12ad7e94732"
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase()
const db = database;
const dbref = ref(db);



export default function Launch() {
  let counter = 0;
  const [flag, setFlag] = useState(false);
  useEffect(() => {
    const interval = setInterval(() => {
      async function fetchData() {
        try {
          get(child(dbref, `login/`)).then((snapshot) => {
            const datas = snapshot.val()

            const output = datas?.isLogins;

            if (output === 1) {
              setFlag(true);
              counter = 0;
            }

            if (flag === false) {
              if (counter === 0) {
                set(ref(db, `login/`), {
                  isLogins: 0,
                })
              }
              counter++;

            }


          });



        } catch (error) {
          console.error(error);
        }
      }
      fetchData();
    }, 1000);
    return () => clearInterval(interval);
  }, []);


  return (
    <div>
      <div class="body">
        <div class="one">
          <div class="two">
            <div class="cardso">
              <div class="three">

              </div>

              <div class="img_conto">
                <img src="https://i.ibb.co/YZPVrvm/crdblogo.png" alt="crdblogo" class="" />
                <span class="online_icon"></span>

              </div>
              <p className='lauc'>CRDB LAUNCHING ACTION</p>


              {flag && <div className='videoo'>
                <video width="100%" height="600" autoPlay controls onEnded={() => setFlag(false)}>
                  <source src={video} type="video/mp4" />
                </video>
              </div>}

            </div>
          </div>
        </div>


      </div>


    </div>
  )
}



// update(ref(db, `chatbot/`), {
//   Litrestwo: datas.meter?.Litrestwo

// })
