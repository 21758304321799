import React, { useEffect, useRef, useState } from 'react'
import '../../App.css'
import axios from 'axios';
import { useTextToVoice } from "react-speakup";
import iconsend from '../../send.png'
import video from '../../one.mp4'

import { initializeApp } from '@firebase/app';
import { getDatabase } from 'firebase/database';
import { ref, set, get, update, remove, child } from "firebase/database";

function StartFirebase() {
  const firebaseConfig = {
    apiKey: "AIzaSyBBDNQ84vjXymTVaoW2b3yHyjIXs4SCfT0",
    authDomain: "smart-wm-bea72.firebaseapp.com",
    databaseURL: "https://smart-wm-bea72-default-rtdb.firebaseio.com",
    projectId: "smart-wm-bea72",
    storageBucket: "smart-wm-bea72.appspot.com",
    messagingSenderId: "221059026615",
    appId: "1:221059026615:web:884c919a5da12ad7e94732"
  };

  const app = initializeApp(firebaseConfig);
  return getDatabase(app);
}

const database = StartFirebase()
const db = database;
const dbref = ref(db);


export default function Chat() {
  const [userInput, setUserInput] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [flag, setFlag] = useState(false);
  const [ flgs, setFlgs] = useState(false);
  const paragraphRef = useRef(null);
  const { speak, isSpeaking, pause, refs, resume, setVoice, voices } =
    useTextToVoice < HTMLDivElement > ({ pitch: 1, rate: 1, volume: 1 });

  const [inputdata, setInputData] = useState('');

  const url = 'https://c-b.onrender.com/get'
  // const url = 'http://127.0.0.1:5000/get'

  const videoRef = useRef(null);

  useEffect(() => {
    paragraphRef.current?.scrollIntoView();
  }, [chatMessages])

  const handleSubmit = async (e) => {
    e.preventDefault();

    const date = new Date();
    const hour = date.getHours();
    const minute = date.getMinutes();
    const str_time = hour + ':' + minute;

    if (userInput !== '') {
      const userMessage = {
        text: userInput,
        time: str_time,
        sender: 'user',
      };

      setChatMessages([...chatMessages, userMessage]);
      setInputData(userInput)
      setUserInput('');

      let word = userInput.split(' ');
      for (let i = 0; i < word.length; i++) {
        let inputText = word[i].toLocaleLowerCase();
        if (inputText === 'activate' || inputText === 'launch') {
          const value = new SpeechSynthesisUtterance('Launching successfully!');
          window.speechSynthesis.speak(value);
          setFlag(true);
          setUserInput('');
          set(ref(db, `login/`), {
            isLogins: 1,
          })
        }
      }

      let data = JSON.stringify({
        msg: userInput,
      });


      try {
        const response = await axios.post(url, data, {
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const botMessage = {
          text: response.data,
          time: str_time,
          sender: 'bot',
        };

        console.log(response?.data)

        setChatMessages([...chatMessages, userMessage, botMessage]);// Add bot message to chatMessages array
        setUserInput('');

        let text = response?.data;
        // let cleanedText = text.replace(/[*:**]/g, '');
        let cleanedText = text.replace(/[*@#$%^&*()_+\=\[\]{};':"\\|,.<>\/?]/g, '');

        const speechValue = new SpeechSynthesisUtterance(cleanedText);
        window.speechSynthesis.speak(speechValue);

      } catch (error) {
        console.error('Error:', error);
      }
    }
  };
  return (
    <div>
      <div class="body">
        <div class="one">
          <div class="two">
            <div class="cards">
              <div class="three">
                <div class="four">
                  <div class="img_cont">
                    <img src="https://i.ibb.co/YZPVrvm/crdblogo.png" alt="crdblogo" class="" />
                    <span class="online_icon"></span>
                  </div>
                  <div class="user_info">
                    <span>CRDB CHATBOT</span>
                    <span id='welc'>Launching Event</span>
                  </div>
                </div>
              </div>

       



              <div id="messageFormeight" class="msg_card_body">
                <div>
                  <div id="messageFormeight">

                    {chatMessages.map((msg, index) => (
                      <div id='imgconts' key={index} className={`d-flex justify-content-${msg.sender === 'user' ? 'end' : 'start'} mb-4`}>
                        {msg.sender === 'bot' && (
                          <div className='usersdata'>
                            <div className="img_conts">
                              <img src="https://i.ibb.co/YZPVrvm/crdblogo.png" alt="user" className="rounded-circle user_img_msg" />
                            </div>
                            <div className={`msg_cotainer${msg.sender === 'user' ? '_send' : ''}`}>
                              {msg.text}
                              <span className={`msg_time${msg.sender === 'user' ? '_send' : ''}`}>{msg.time}</span>
                            </div>

                          </div>
                        )}


                        {msg.sender === 'user' && (
                          <div className='usersdatas'>
                            <div className={`msg_cotainer${msg.sender === 'user' ? '_send' : ''}`}>
                              {msg.text}
                              <span className={`msg_time${msg.sender === 'user' ? '_send' : ''}`}>{msg.time}</span>
                            </div>
                            <div className="img_conts">
                              <img src="https://i.ibb.co/d5b84Xw/Untitled-design.png" alt="user" className="rounded-circle user_img_msg" />
                            </div>
                          </div>
                        )}


                      </div>
                    ))}

                    <div ref={paragraphRef} />

                  </div>
                </div>

              </div>

              <div class="below">
                <form id="messageArea" class="input-group">
                  <input
                    type="text"
                    id="text"
                    name="msg"
                    placeholder="Type your message..."
                    autocomplete="off"
                    class="form-control type_msg"
                    required
                    value={userInput}
                    onChange={(e) => setUserInput(e.target.value)}
                    onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)} // Add this line

                  />
                  <div class="mybuton" onClick={handleSubmit} >
                    <img src={iconsend} alt="user" />
                  </div>
                </form>
              </div>

            </div>
          </div>
        </div>


      </div>



    </div>
  )
}
